import React from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts";

const StyledStatus = styled.div`
  ${fonts.montserratBold};
  color: ${props => props.color};
`;

const Status = ({children, color}) => {
  return (
    <StyledStatus color={color}>
      {children}
    </StyledStatus>);
};

export default Status
import api from "./core/api";

const BASE_PATH = '/log';

async function getLog(id) {
  return await api.get(BASE_PATH + '/' + id);
}

const github = {
  getLog: getLog,
};

export default github;

import React from "react"
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";

const Container = styled.div`
  width: 100%;
  display: ${props => props.center && "flex"};
  align-items: center;
  padding-right: 40px;
  
  @media(min-width: ${breakpoints.md}) {
    width: 75%;
  }
`;

const IntroTitle = ({children, center}) => {
  return (
    <Container center={center}>
      {children}
    </Container>
  );
};

export default IntroTitle

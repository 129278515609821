import React from "react"

const PanelTitle = ({children}) => {
  return (
    <h2>
      {children}
    </h2>
  );
};

export default PanelTitle
import React from "react";
import styled from "styled-components";
import Status from "../Core/Deployment/Status";
import {getFormattedDate, getStatusColor, getStatusDisplayText} from "../../services/deployment";
import ExternalLink from "../Core/ExternalLink";
import ThemePreviewUrl from "../Core/Deployment/ThemePreviewUrl";

const Container = styled.div`
  width: 100%;
`;

const Branch = styled.div`
`;

const DeploymentDate = styled.div`
  margin: 12px 0;
`;

const ThemePreview = styled.div`
`;

const LogIntro = ({deployment}) => {
  const {status, trigger, commitId, commitUrl, commitMessage, deploymentTime, previewUrl, isPublished} = deployment;

  const showPreviewUrl = previewUrl && status === "completed";

  return (
    <Container>
      <Status color={getStatusColor(deployment)}>
        {getStatusDisplayText(deployment)}
      </Status>
      <Branch>
        {deployment['ref']} @ <ExternalLink href={commitUrl}>{commitId}</ExternalLink>
        {trigger === "webhook" && <p>Triggered by github commit</p>}
        {commitMessage && <p>{commitMessage}</p>}
      </Branch>
      <DeploymentDate>
        {getFormattedDate(deployment) && <p>{getFormattedDate(deployment)}</p>}
        {(status === "completed" && deploymentTime) && <p>Deployed in <strong>{deploymentTime}</strong></p>}
        {(status === "failed" && deploymentTime) && <p>Deployment time <strong>{deploymentTime}</strong></p>}
      </DeploymentDate>
      <ThemePreview>
        {showPreviewUrl && <ExternalLink href={previewUrl}>{isPublished ? 'View live theme' : 'Preview theme'}</ExternalLink>}
      </ThemePreview>
    </Container>
  );
};

export default LogIntro;

import React from "react"
import styled from "styled-components"
import colors from "../../../styles/colors";
import breakpoints from "../../../styles/breakpoints";

const Container = styled.div`
  width: 100%;
  padding: 24px 32px;
  border: 1px solid ${colors.greyDark};
  border-radius: 0 0 8px 8px ;
  border-top: 0;
  color: ${props => props.color ? props.color : colors.black};
  background: ${props => props.background ? props.background : colors.white};
  
  @media(min-width: ${breakpoints.sm}) {
    padding: 40px 100px;
  }
`;

const PanelContent = ({children, background, color}) => {
  return (
    <Container background={background} color={color}>
      {children}
    </Container>
  );
};

export default PanelContent
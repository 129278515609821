import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
`;

const Panel = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default Panel
import React, {useState} from "react"
import styled from "styled-components"
import clipboardImage from "../../../resources/img/icons/clipboard.svg";
import ExternalLink from "../ExternalLink";
import breakpoints from "../../../styles/breakpoints";
import colors from "../../../styles/colors";

const PreviewLink = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  
  @media(min-width: ${breakpoints.xl}) {
    width: auto;
  }
`;

const PreviewInputUrl = styled.div`
  ${props => props.url && `
    background: ${colors.greyLight};
    border: 1px solid ${colors.greyLight};
  `}
  color: ${colors.black};
  text-decoration: none;
  border-radius: 8px;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  padding: 8px 12px;
  
  @media(min-width: ${breakpoints.xl}) {
    width: 280px;
    padding: 8px 0 8px 12px;
  }
`;

const LinkUrl = styled(ExternalLink)`
  text-decoration: underline;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  
  &:hover {
    text-decoration: underline;
  }
  
  @media(min-width: ${breakpoints.xl}) {
    text-decoration: none;
    max-width: 240px;
  }
`;

const LinkImage = styled.button`
  display: none;
  border: 1px solid ${colors.greyLight};
  background: ${colors.greyLight};
  cursor: pointer;
  outline: none;
  padding: 4px 8px;
  z-index: 5;
  
  @media(min-width: ${breakpoints.xl}) {
    display: flex;
    align-items: center;
  }
`;

const Blur = styled.span`
  position: absolute;
  right: 24px;
  top: 8px;
  width: 12px;
  height: 24px;
  background: #F8F8F8;
  opacity: 0.8;
  z-index: 2;
  filter: blur(1px);
  display: none;
  
  @media(min-width: ${breakpoints.xl}) {
    display: block;
  }
`;

const Tooltip = styled.span`
  transition: opacity 0.4s;
  opacity: ${props => props.show ? 1 : 0};
  position: absolute;
  top: -26px;
  right: 26px;
  font-size: 12px;
`;

const ThemePreviewUrl = ({previewUrl, status}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = (e, url) => {
    e.preventDefault();

    navigator.clipboard.writeText(url)
      .then(() => {
        setShowTooltip(true);

        setTimeout(function () {
          setShowTooltip(false);
        }.bind(this), 800);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showPreviewUrl = previewUrl && status === "completed";

  return (
    <PreviewLink>
      <Tooltip show={showTooltip}>Copied!</Tooltip>
      <PreviewInputUrl url={showPreviewUrl}>
        {showPreviewUrl &&
        <>
          <Blur/>
          <LinkUrl href={previewUrl}>{previewUrl}</LinkUrl>
          <LinkImage onClick={(e) => copyToClipboard(e, previewUrl)}>
            <img src={clipboardImage} alt={"Copy link"}/>
          </LinkImage>
        </>
        }
      </PreviewInputUrl>
    </PreviewLink>
  );
};

export default ThemePreviewUrl;
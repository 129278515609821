import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints";

const Container = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  
  @media(min-width: ${breakpoints.md}) {
    width: ${props => props.auto ? "auto" : "25%"};
  }
`;

const IntroContent = ({children, auto}) => {
  return (
    <Container auto={auto}>
      {children}
    </Container>
  );
};

export default IntroContent

import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints";

const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: space-between;
  max-width: 100vw;
  
  @media(min-width: ${breakpoints.md}) {
    flex-direction: row;
  }

`;

const Intro = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default Intro

import colors from "../styles/colors";

const getStatusColor = ({status}) => {
  let statusColor;

  switch (status) {
    case "queued":
      statusColor = colors.greyDarker;
      break;
    case "pending":
      statusColor = colors.orange;
      break;
    case "in_progress":
      statusColor = colors.blue;
      break;
    case "completed":
      statusColor = colors.green;
      break;
    case "failed":
      statusColor = colors.red;
      break;
    case "plan_limit_exceeded":
      statusColor = colors.red;
      break;
    default:
      statusColor = colors.greyDark;
      break;
  }

  return statusColor;
};

const getStatusDisplayText = ({status, isPublished}) => {
  let statusText = status.charAt(0).toUpperCase() + status.slice(1);

  if (isPublished) {
    statusText = "Published";
  } else {
    statusText = statusText.replaceAll('_', ' ');
  }

  return statusText;
};

const getFormattedDate = ({displayDate}) => {
  let formattedDate;

  if (displayDate) {
    const displayDateData = new Date(displayDate);
    const today = new Date();

    if (displayDateData.getDate() === today.getDate() &&
      displayDateData.getMonth() === today.getMonth() &&
      displayDateData.getFullYear() === today.getFullYear()) {

      formattedDate = 'Today at ' + new Intl.DateTimeFormat("en-US", {
        hour: 'numeric',
        minute: 'numeric',
      }).format(displayDateData);
    } else {
      formattedDate = new Intl.DateTimeFormat("en-US", {
        dateStyle: 'long',
        timeStyle: 'short'
      }).format(displayDateData);
    }
  }

  return formattedDate;
};

export {
  getFormattedDate,
  getStatusColor,
  getStatusDisplayText
}

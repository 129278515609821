import api from "./core/api";

const BASE_PATH = '/dashboard';

async function setAutoPublish(isAutoPublish) {
  return await api.post(BASE_PATH + '/autopublish', {isAutoPublish});
}

async function getDashboard(page = null) {
  return await api.get(BASE_PATH + '/page/' + page);
}

async function subscribe() {
  return await api.get(BASE_PATH + '/subscribe');
}

async function deploy(branch) {
  return await api.post(BASE_PATH + '/deploy', {branch});
}

const dashboard = {
  getDashboard: getDashboard,
  setAutoPublish: setAutoPublish,
  subscribe: subscribe,
  deploy: deploy
};

export default dashboard;

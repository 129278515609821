import React, {useEffect, useState} from "react";
import PrivateRoute from "../router/PrivateRoute";
import PageData from "../components/Core/PageData";
import log from "../backend/log";
import {navigate} from "gatsby";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Core/Seo";
import PanelHeader from "../components/Core/Panel/PanelHeader";
import Panel from "../components/Core/Panel/Panel";
import PanelContent from "../components/Core/Panel/PanelContent";
import PanelTitle from "../components/Core/Panel/PanelTitle";
import PanelActions from "../components/Core/Panel/PanelActions";
import styled from "styled-components";
import colors from "../styles/colors";
import Intro from "../components/Core/Intro/Intro";
import IntroTitle from "../components/Core/Intro/IntroTitle";
import IntroContent from "../components/Core/Intro/IntroContent";
import LogIntro from "../components/Log/LogIntro";
import {getStatusDisplayText} from "../services/deployment";
import fonts from "../styles/fonts";
import dashboard from "../backend/dashboard";
import syncImage from "../resources/img/icons/sync-white.svg";
import breakpoints from "../styles/breakpoints";

const LogContent = styled.div`
  font-size: 16px;
  line-height: 32px;
`;

const StyledButton = styled.button`
  ${fonts.montserratBold};
  font-size: 12px;
  line-height: 24px;
  padding: 8px 16px 8px 40px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  display: none;

  color: ${colors.white};
  background: url("${syncImage}") ${colors.black} no-repeat;
  background-position: 12px 50%;
  border: 1px solid ${colors.black};
  
  &:hover {
    background-color: ${colors.green};
    color: ${colors.white};
    border-color: ${colors.green};
  }
  
  @media(min-width: ${breakpoints.sm}) {
    display: flex;
  }
`;

const LogPage = ({location, '*': logId}) => {
  const isCancelled = React.useRef(false);
  const [pageData, setPageData] = useState(null);
  const path = location.pathname;

  useEffect(() => {
    log.getLog(logId)
      .then(async ({data}) => {
        setPageData(data);
      })
      .catch(() => {
        navigate('/');
      });

    return () => {
      isCancelled.current = true;
    };
  }, []);

  const deploy = (branch) => {
    dashboard.deploy(branch)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
      })
  };

  const deployBranch = async (e, branch) => {
    e.preventDefault();

    setTimeout(function () {
      if (isCancelled.current) {
        return;
      }

      deploy(branch);
    }, 0);
  };

  const logText = pageData?.log;
  const deployment = pageData?.deployment;

  let pageTitle;

  if (deployment) {
    const statusDisplayText = getStatusDisplayText(deployment);

    if (statusDisplayText === "In Progress") {
      pageTitle = "Deployment in progress";
    } else {
      pageTitle = `${statusDisplayText} Deployment`;
    }
  }

  return (
    <Layout>
      <SEO
        title={"Deploy Log"}
        description={"Deployment information and detailed log"}
        fullTitle={true}
        path={path}/>
      <PageData hasData={pageData}>
        <Intro>
          <IntroTitle>
            {(pageData && deployment && pageTitle) && <h1>{pageTitle}</h1>}
          </IntroTitle>
          {(pageData && deployment) &&
          <IntroContent>
            <LogIntro deployment={deployment} />
          </IntroContent>}
        </Intro>
        <Panel>
          <PanelHeader>
            <PanelTitle>
              Deployment Log
            </PanelTitle>
            <PanelActions>
              <StyledButton onClick={(e) => deployBranch(e, deployment['ref'])}>
                Retry deployment
              </StyledButton>
            </PanelActions>
          </PanelHeader>
          <PanelContent background={colors.white} color={colors.black}>
            <LogContent>
              {(pageData && logText) && logText.split('\n').map(str => <>{str}<br /></>)}
            </LogContent>
          </PanelContent>
        </Panel>
      </PageData>
    </Layout>
  );
};

function privateLogPage(props) {
  return <PrivateRoute component={LogPage} {...props}/>
}

export default privateLogPage;

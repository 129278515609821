import React from "react"
import styled from "styled-components"
import colors from "../../../styles/colors";

const Container = styled.div`
  width: 100%;
  padding: 24px 32px;
  border: 1px solid ${colors.greyDark};
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.greyLightPanel};
  
  h2 {
    margin: 0;
  }  
`;

const PanelHeader = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default PanelHeader